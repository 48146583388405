import IMG_intro from './../assets/img/bg.jpg';

import IMG_01 from './../assets/img/gallery/1.jpg';
import IMG_02 from './../assets/img/gallery/2.jpg';
import IMG_03 from './../assets/img/gallery/3.jpg';
import IMG_04 from './../assets/img/gallery/4.jpg';
import IMG_05 from './../assets/img/gallery/5.jpg';
import IMG_06 from './../assets/img/gallery/6.jpg';
import IMG_07 from './../assets/img/gallery/7.jpg';
import IMG_07a from './../assets/img/gallery/7a.jpg';
import IMG_08 from './../assets/img/gallery/8.jpg';
import IMG_09 from './../assets/img/gallery/9.jpg';
import IMG_09a from './../assets/img/gallery/9a.jpg';
import IMG_09b from './../assets/img/gallery/9b.jpg';
import IMG_10 from './../assets/img/gallery/10.jpg';
import IMG_11 from './../assets/img/gallery/11.jpg';
import IMG_12 from './../assets/img/gallery/12.jpg';
import IMG_13 from './../assets/img/gallery/13.jpg';
import IMG_14 from './../assets/img/gallery/14.jpg';
import IMG_15 from './../assets/img/gallery/15.jpg';
import IMG_16 from './../assets/img/gallery/16.jpg';
import IMG_17 from './../assets/img/gallery/17.jpg';
import IMG_18 from './../assets/img/gallery/18.jpg';
import IMG_19 from './../assets/img/gallery/19.jpg';
import IMG_19a from './../assets/img/gallery/19a.jpg';
import IMG_20 from './../assets/img/gallery/20.jpg';
import IMG_21 from './../assets/img/gallery/21.jpg';

const img: any = {
    logo: 'asd',
    intro: IMG_intro,
    // img1: IMG_img1,
    front: 'asd',
    city: 'IMG_city',
    idea: 'IMG_idea',
    collage: 'IMG_collage',
    gallery1: 'IMG_gallery1',
    gallery2: 'IMG_gallery2',
    gallery3: 'IMG_gallery3',

    img1: IMG_01,
    img2: IMG_02,
    img3: IMG_03,
    img4: IMG_04,
    img5: IMG_05,
    img6: IMG_06,
    img7: IMG_07,
    img7a: IMG_07a,
    img8: IMG_08,
    img9: IMG_09,
    img9a: IMG_09a,
    img9b: IMG_09b,
    img10: IMG_10,
    img11: IMG_11,
    img12: IMG_12,
    img13: IMG_13,
    img14: IMG_14,
    img15: IMG_15,
    img16: IMG_16,
    img17: IMG_17,
    img18: IMG_18,
    img19: IMG_19,
    img19a: IMG_19a,
    img20: IMG_20,
    img21: IMG_21,
};

export default img;
