import { Dispatch } from 'redux';
import { SET_LOCALE, AvailableLocales, TranslationActionTypes } from './types';

import messages_en from '../../i18n/en.json';
import messages_bg from '../../i18n/bg.json';

const newMessages = (locale: string) => {
    if (locale === 'en') {
        return messages_en;
    }

    if (locale === 'bg') {
        return messages_bg;
    }
};

export const setLanguage = (newLocale?: AvailableLocales) => async (
    dispatch: Dispatch<TranslationActionTypes>
    // getState: () => AppState
): Promise<void> => {
    const locale = newLocale || 'en'; // more advaced check needed
    const messages = newMessages(locale);

    dispatch({
        type: SET_LOCALE,
        payload: {
            locale,
            messages,
        },
    });
};
