import React, { FunctionComponent } from 'react';
// import styled from 'styled-components/macro';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

// import { breakpoints } from 'src/config';

const MapContainer: FunctionComponent = () => {
    return (
        <Map
            google={window.google}
            initialCenter={{ lat: 42.70082326926946, lng: 27.71432926910466 }}
            style={{ width: '100%', height: '700px' }}
        >
            <Marker />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDZpKrfhs_w_eZaWxUgAxoKmYTjdrYTDjc',
})(MapContainer);
