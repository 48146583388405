import React, { FunctionComponent, RefObject } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import img from '../assets/Images';
import { breakpoints } from 'src/config';

type Props = {
    reference: RefObject<any>;
};

const Intro: FunctionComponent<Props> = ({ reference }) => {
    return (
        <IntroWrapper ref={reference}>
            <IntroInner>
                <Message>
                    <FormattedMessage id="SLOGAN" />
                </Message>
                <Separator />
                <Heading1>
                    <FormattedMessage id="HOTEL_KLISURA" />
                </Heading1>
                <Heading2>
                    <FormattedMessage id="SUNNY_BEACH" />
                </Heading2>
                <Starts>
                    &#9733; <span>&#9733;</span> &#9733;
                </Starts>
            </IntroInner>
        </IntroWrapper>
    );
};

const IntroWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;

    background-color: #333;
    background-image: url(${img.intro});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @media ${breakpoints.tablet} {
        // height: 260px;
    }
`;

const IntroInner = styled.div`
    position: absolute;
    color: #37a5b1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 250px 0 150px 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.9) 30%,
        rgba(255, 255, 255, 0.7) 55%,
        rgba(255, 255, 255, 0.7) 70%,
        rgba(255, 255, 255, 0.6) 80%,
        rgba(255, 255, 255, 0.3) 90%,
        rgba(255, 255, 255, 0) 100%
    );
    text-align: center;

    @media ${breakpoints.tablet} {
        // height: 260px;
    }
`;

const Heading1 = styled.h1`
    font-size: 60px;
    letter-spacing: 5px;
    margin: 0;
    font-weight: 500;
    padding: 10px 0;

    @media ${breakpoints.smallDesktop} {
        font-size: 38px;
        letter-spacing: 2px;
    }
`;

const Heading2 = styled.h2`
    // font-size: 38px;
    font-size: 50px;
    font-weight: lighter;
    letter-spacing: 5px;
    margin: 0;
    // font-weight: 500;

    @media ${breakpoints.smallDesktop} {
        font-size: 28px;
        letter-spacing: 3px;
        padding-left: 3px;
        word-spacing: 5px;
    }
`;

const Message = styled.p`
    font-size: 21px;
    letter-spacing: 2px;
    padding: 12px 0;
    margin: 0;
    font-weight: 500;

    @media ${breakpoints.smallDesktop} {
        font-size: 18px;
        letter-spacing: 1px;
        padding-left: 10px;
        word-spacing: 5px;
    }
`;

const Starts = styled.p`
    font-size: 60px;
    margin: 0;
    padding-top: 20px;

    span {
        font-size: 85px;
    }
`;

const Separator = styled.span`
    width: 650px;
    height: 2px;
    background: #37a5b1;
    margin: auto;
    margin-top: 10px;

    @media ${breakpoints.smallDesktop} {
        width: 300px;
    }

    @media ${breakpoints.tablet} {
        width: 200px;
    }
`;

export default Intro;
