const version = 'v1';
const ns = `klisura-${version}`;

const getStore = () => {
    const store = localStorage.getItem(ns);

    return store ? JSON.parse(store) : {};
};

export const getItem = (key: string) => {
    const store = getStore();

    return key in store ? store[key] : null;
};

export const setItem = (key: string, value: any) => {
    const store = getStore();

    store[key] = value;

    localStorage.setItem(ns, JSON.stringify(store));
};

export const removeItem = (key: string) => {
    const store = getStore();

    delete store[key];

    localStorage.setItem(ns, JSON.stringify(store));
};

export const resetStorage = () => localStorage.setItem(ns, JSON.stringify({}));

// On module init; make sure that the store always exists in localStorage
if (!localStorage.getItem(ns)) {
    resetStorage();
}
