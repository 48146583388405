import React, { FunctionComponent, RefObject } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { breakpoints } from 'src/config';

type Props = {
    reference: RefObject<any>;
};

const Info: FunctionComponent<Props> = ({ reference }) => {
    return (
        <Wrapper>
            <ToScroll ref={reference} />
            <InnerWrapper>
                <h1>
                    <FormattedMessage id="ABOUT_THE_HOTEL" />
                </h1>
                <p>
                    <FormattedMessage id="ABOUT_LINE_1" />
                </p>
                <p>
                    <FormattedMessage id="ABOUT_LINE_2" />
                </p>
                <p>
                    <FormattedMessage id="ABOUT_LINE_3" />
                </p>
                <p>
                    <FormattedMessage id="ABOUT_LINE_4" />
                </p>

                <h1>
                    <FormattedMessage id="FACILITIES" />
                </h1>

                <ul>
                    <li>
                        <FormattedMessage id="RESTAURANT" />
                    </li>
                    <li>
                        <FormattedMessage id="POOLS" />
                    </li>
                    <li>
                        <FormattedMessage id="BARS" />
                    </li>
                    <li>
                        <FormattedMessage id="SNACKBAR" />
                    </li>
                    <li>
                        <FormattedMessage id="BILLARD" />
                    </li>
                    <li>Wi-Fi</li>
                    <li>
                        <FormattedMessage id="PARKING" />
                    </li>
                    <li>
                        <FormattedMessage id="PLAYGROUND" />
                    </li>
                    <li>
                        <FormattedMessage id="FAMILY_ROOMS" />
                    </li>
                    <li>
                        <FormattedMessage id="APARTMENTS" />
                    </li>
                    <li>
                        <FormattedMessage id="TERRACES" />
                    </li>
                    <li>
                        <FormattedMessage id="AIR_CONDITIONERS" />
                    </li>
                    <li>
                        <FormattedMessage id="GARDEN" />
                    </li>
                    <li>
                        <FormattedMessage id="RECEPTION_24" />
                    </li>
                    <li>
                        <FormattedMessage id="SMOKING_AREA" />
                    </li>
                    <li>
                        <FormattedMessage id="SAFE" />
                    </li>
                    <li>
                        <FormattedMessage id="CLEANING" />
                    </li>
                    <li>
                        <FormattedMessage id="ELEVATOR" />
                    </li>
                    <li>
                        <FormattedMessage id="ROOMSERVICE" />
                    </li>
                    <li>
                        <FormattedMessage id="TRANSFER" />
                    </li>
                </ul>
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background: rgb(55, 165, 177);
    background: linear-gradient(90deg, rgba(55, 165, 177, 1) 0%, rgba(59, 186, 200, 1) 35%, #72c0d0 100%);
`;

const InnerWrapper = styled.div`
    max-width: 1024px;
    margin: auto;
    padding: 10px;
    color: #fff;

    h1 {
        font-weight: 300;
        font-size: 36px;
    }

    p {
        letter-spacing: 1px;
        line-height: 21px;
    }

    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    li {
        margin-left: 0;
        display: inline;
        padding: 12px 20px;
        border: 1px solid #fff;
        border-radius: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: rgba(255, 255, 255, 0.2);
        cursor: default;

        &:hover {
            background: #fff;
            color: #333;
        }

        span {
            font-size: 30px;
        }
    }

    @media ${breakpoints.tablet} {
        li {
            font-size: 14px;
            padding: 8px 12px;
            border: none;
            border-radius: 100px;
            margin-right: 10px;
            margin-bottom: 10px;

            span {
                font-size: 30px;
            }
        }
    }
`;

const ToScroll = styled.span`
    position: absolute;
    margin-top: -80px;
`;

export default Info;
