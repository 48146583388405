import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components/macro';

import Navigation from '../components/Navigation';
import Intro from '../components/Intro';
import Gallery from '../components/Gallery';
import Info from '../components/Info';
import Contacts from '../components/Contacts';
// import Footer from '../components/Footer';

// import img from '../assets/Images';
// import { breakpoints } from 'src/config';

const HomeLayout: FunctionComponent = () => {
    const sectionHome = useRef();
    const sectionGallery = useRef();
    const sectionAbout = useRef();
    const sectionContacts = useRef();

    const scrollTo = (section: string) => {
        if (section === 'home') {
            const home = sectionHome.current as any;
            home.scrollIntoView({ behavior: 'smooth' });
        } else if (section === 'gallery') {
            const gallery = sectionGallery.current as any;
            gallery.scrollIntoView({ behavior: 'smooth' });
        } else if (section === 'about') {
            const about = sectionAbout.current as any;
            about.scrollIntoView({ behavior: 'smooth' });
        } else if (section === 'contacts') {
            const contacts = sectionContacts.current as any;
            contacts.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <MainWrapper>
            <Navigation scrollTo={scrollTo} />

            <Intro reference={sectionHome} />

            <Gallery reference={sectionGallery} />

            <Info reference={sectionAbout} />

            <Contacts reference={sectionContacts} />
        </MainWrapper>
    );
};
const MainWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
`;

// const MainFooter = styled(Footer)``;

export default HomeLayout;

/* import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { PrimaryButton } from 'src/components/shared/Buttons/PrimaryButton';
import { OutlineButton } from 'src/components/shared/Buttons/OutlineButton';
import HomeMenu from '../components/home/HomeMenu';
import HomeLayout from 'src/layouts/HomeLayout';
import { breakpoints } from 'src/config';
import Card from 'src/components/shared/Card';
import RandomProducts from '../components/RandomProducts';
import img from 'src/assets/Images';
import icon from 'src/assets/Icons';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

type Props = ReturnType<typeof mapDispatchToProps>;

const Home: FunctionComponent<Props> = () => {
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop - 20);
    const aboutRef = useRef(null);
    const executeScroll = () => scrollToRef(aboutRef);

    const mainMessage =
        'Истинска тухла, истински цветове, истинска красота! Внесете уюта, топлината и красотата на истинската тухла във вашето интериорно или екстериорно пространство без да правите компромиси с качеството! Избирайки декоративни облицовъчни тухлички Cerrawall, вие избирате не само красив и неостаряващ дизайн, но получавате и най-доброто качество и обслужване! Ние можем да Ви предложим индивидуално решение, отговарящо на нуждите на вашият проект. Разгледайте продуктовата ни гама в секция "Продукти", а при всякакви въпроси ние сме насреща да помогнем с реализацията на вашият проект.';

    return (
        <HomeLayout>
            {false && (
                <Card
                    heading="Истинските тънки тухли"
                    content={mainMessage}
                    actions={
                        <>
                            <ProductsButton as={NavLink} to="/products">
                                Продукти
                            </ProductsButton>
                            <PrimaryButton onClick={executeScroll}>Научи повече</PrimaryButton>
                        </>
                    }
                />
            )}

            <HomeMenu />

            <WhyUs ref={aboutRef}>
                Защо да изберете{' '}
                <strong>
                    <MainColor>Cerrawall</MainColor>
                </strong>
            </WhyUs>

            <Collage />

            <Card
                icon={<BrickIcon />}
                heading="Истински тухли"
                content="В производството на нашите тухли ние не използваме химически заместители на изпечената
                    глина, която е доказала своите качества и ефективност през изминалите векове.
                    Разчитайки на традицията, ние Ви предоставяме истински изпечени глинени тухли."
            />

            <Card
                icon={<WidthIcon />}
                heading="Наистина тънки"
                content="Вече няма нужда да отнемате повече пространство и да добавяте допълнителна тежест към
                    стените за да постигнете автентичната визия на тухления зид. Нашите декоративни тухли са
                    изключително леки и тънки, с дебелина започваща само от 8 мм. По този начин можете да имате истински
                    тухлен зид, независимо от пространството, с което разполагате."
            />

            <Card
                icon={<InstallIcon />}
                heading="Монтират се лесно"
                content="Тънките тухли са все по-предпочитан вариант за облицоване на стени, което се
                    дължи на тяхната устойчивост, неостаряващата красота, лесната поддръжка, както и лесната
                    инсталация. Лесният монтаж значително намалява времето за завършване на проекта ви, както и цената му. Винаги можете да се
                    обърнете към нас за консултация."
            />

            <Card
                icon={<OriginalIcon />}
                heading="Автентичен продукт"
                content="С избора на тънки тухли Cerrawall не избирате просто облицовка. Вие избирате продукт
                    с класически дизайн, който е бил актуален хиляди години и продължава да е актуален и днес. А
                    още повече, че тухлите се вписват идеално дори в най-модерните интериорни и екстериорни
                    проекти, което може да Ви гарантира, че получавате един неостаряващ и вечно красив продукт."
            />

            <Card
                icon={<RecylcleIcon />}
                heading="Рециклираме"
                content="Днес е по-важно от всякога да опазим планетата ни от огромното строително и индустриално замръсяване.
                Поради тази причина, освен че продуктите ни не са замръсители, опитваме се да заменим целия ни процес на работа,
                така че да преизползваме ресурсите си максимално и да отделяме минимално количество отпадъци."
            />

            <Heading>Продукти, които може да харесате</Heading>
            <RandomProducts productsToShow={2} />
        </HomeLayout>
    );
};

const Collage = styled.div`
    width: 100%;
    height: 309px;
    background: #333333 url(${img.collage}) no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 50px;
    border: 0;

    @media ${breakpoints.smallDesktop} {
        height: 220px;
    }

    @media ${breakpoints.tablet} {
        height: 140px;
    }
`;

const WhyUs = styled.h1`
    text-align: center;
    color: #666;
    letter-spacing: 1px;
    font-weight: 100;
    margin-bottom: 50px;
`;

const MainColor = styled.span`
    color: rgba(238, 158, 146);
`;

// @todo - export to card component
const Icon = styled.i`
    width: 100px;
    height: 100px;
    opacity: 0.8;
`;

const InstallIcon = styled(Icon)`
    content: url(${icon.install});
`;

const WidthIcon = styled(Icon)`
    content: url(${icon.width});
`;

const BrickIcon = styled(Icon)`
    content: url(${icon.brick});
`;

const OriginalIcon = styled(Icon)`
    content: url(${icon.original});
`;

const RecylcleIcon = styled(Icon)`
    content: url(${icon.recylce});
`;

const ProductsButton = styled(OutlineButton)`
    margin-right: 20px;

    @media ${breakpoints.tablet} {
        margin-right: 0;
        margin-bottom: 10px;
    }
`;

const Heading = styled.h2`
    font-weight: 300;
`;

export default connect(null, mapDispatchToProps)(Home);
 */
