import React, { FunctionComponent, RefObject } from 'react';
import styled from 'styled-components/macro';
import emailjs from 'emailjs-com';
import { FormattedMessage } from 'react-intl';

import { breakpoints } from 'src/config';

import Map from './Map';

type Props = {
    reference: RefObject<any>;
};

const Info: FunctionComponent<Props> = ({ reference }) => {
    function sendEmail(e: any) {
        e.preventDefault();

        emailjs.sendForm('default_service', 'template_1y2vvy9', e.target, 'user_yszkeXfcnz0R3dTgj9NkR').then(
            (result) => {
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    return (
        <Wrapper>
            <ToScroll ref={reference} />
            <InnerWrapper id="home">
                <h1>
                    <FormattedMessage id="CONTACTS" />
                </h1>
                <p>
                    <FormattedMessage id="CONTACTS_LINE_1" />
                </p>
                <p>
                    <FormattedMessage id="CONTACTS_LINE_2" />
                </p>
                <p>
                    <FormattedMessage id="CONTACTS_LINE_3" />
                </p>
                <p>E-mail: klisurahotel@gmail.com</p>

                <h1>
                    <FormattedMessage id="CONTACT_FORM" />
                </h1>
                <p>
                    <FormattedMessage id="CONTACT_FORM_LINE_1" />
                </p>
                <Form className="contact-form" onSubmit={sendEmail}>
                    <label>
                        <FormattedMessage id="FORM_PHONE" />
                        <input type="phone" name="phone" />
                    </label>
                    <label>
                        <FormattedMessage id="FORM_NAME" />
                        <input type="text" name="name" required />
                    </label>
                    <label>
                        E-mail
                        <input type="email" name="email" required />
                    </label>
                    <label>
                        <FormattedMessage id="FORM_MESSAGE" />
                        <textarea name="message" required />
                    </label>
                    <button type="submit">
                        <FormattedMessage id="SUBMIT" />
                    </button>
                </Form>
            </InnerWrapper>

            <MapWrapper>
                <Map />
            </MapWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background: rgb(255, 255, 255);
    // background: linear-gradient(90deg, rgba(55, 165, 177, 1) 0%, rgba(59, 186, 200, 1) 35%, #72c0d0 100%);
`;

const InnerWrapper = styled.div`
    max-width: 1024px;
    margin: auto;
    padding: 10px;
    color: #444;

    h1 {
        font-weight: 300;
        font-size: 36px;
    }

    p {
        letter-spacing: 1px;
        line-height: 21px;
    }

    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    li {
        margin-left: 0;
        display: inline;
        padding: 12px 20px;
        border: 1px solid #fff;
        border-radius: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        background: rgba(255, 255, 255, 0.2);
        cursor: default;

        &:hover {
            background: #fff;
            color: #333;
        }

        span {
            font-size: 30px;
        }
    }

    @media ${breakpoints.tablet} {
        li {
            font-size: 14px;
            padding: 8px 12px;
            border: none;
            border-radius: 100px;
            margin-right: 10px;
            margin-bottom: 10px;

            span {
                font-size: 30px;
            }
        }
    }
`;

const MapWrapper = styled.div`
    position: relative;
`;

const Form = styled.form`
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    label {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    input,
    textarea {
        padding: 10px;
        margin-top: 10px;
        border: 1px solid #aaa;
        border-radius: 5px;
        outline: none;
    }

    button {
        border-radius: 5px;
        outline: none;
        border: 1px solid #52c1ce;
        background: #52c1ce;
        padding: 20px;
        margin-bottom: 30px;
        color: #fff;
        font-size: 21px;
        cursor: pointer;
        transition: all 0.3s;
    }

    button:hover {
        background: #37a5b1;
    }
`;

const ToScroll = styled.span`
    position: absolute;
    margin-top: -80px;
`;

export default Info;
