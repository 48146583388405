import React, { useState, FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// @todo - delete reach listbox and menu

import { setItem } from '../services/storage';
import { setLanguage } from '../store/i18n/actions';
import { breakpoints } from '../config';

import color from './../assets/Colors';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ setLanguage }, dispatch);

type Props = {
    scrollTo?(section: string): void;
} & ReturnType<typeof mapDispatchToProps>;

const Navigation: FunctionComponent<Props> = ({ scrollTo, setLanguage }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [preferredLocale, setPreferredLocale] = useState('en');

    const setNewLocale = (value: 'en' | 'bg') => {
        setItem('language', value);
        setLanguage(value);
        setPreferredLocale(value);
    };

    const performScroll = (section: string) => {
        setShowMenu(!showMenu);
        if (scrollTo) {
            scrollTo(section);
        }
    };

    return (
        <Route>
            <Wrapper>
                <MenuWrapper>
                    <MenuLinksWrapper showMenu={showMenu}>
                        <ListElement>
                            <Link onClick={() => performScroll('home')}>
                                <FormattedMessage id="HOME" />
                            </Link>
                        </ListElement>

                        <ListElement>
                            <Link onClick={() => performScroll('gallery')}>
                                <FormattedMessage id="GALLERY" />
                            </Link>
                        </ListElement>

                        <ListElement>
                            <Link onClick={() => performScroll('about')}>
                                <FormattedMessage id="FACILITIES" />
                            </Link>
                        </ListElement>

                        <ListElement>
                            <Link onClick={() => performScroll('contacts')}>
                                <FormattedMessage id="CONTACTS" />
                            </Link>
                        </ListElement>

                        <ListElement>
                            <LocaleSelect value={preferredLocale} onChange={(e: any) => setNewLocale(e.target.value)}>
                                <option value="en">EN</option>
                                <option value="bg">BG</option>
                            </LocaleSelect>
                        </ListElement>
                    </MenuLinksWrapper>

                    <MenuOpener onClick={() => setShowMenu(!showMenu)}>...</MenuOpener>
                </MenuWrapper>
            </Wrapper>
        </Route>
    );
};

const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    background: rgb(55 165 177);
    display: block;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 6px 0px;
    box-sizing: border-box;
    z-index: 100;

    @media ${breakpoints.tablet} {
        height: 80px;
    }
`;

const MenuWrapper = styled.div`
    position: relative;
    max-width: 1408px;
    height: 80px;
    margin: auto;
    padding: 0 10px;

    @media ${breakpoints.tablet} {
        height: 80px;
    }
`;

const MenuLinksWrapper = styled.ul<{ showMenu: boolean }>`
    position: relative;
    display: block;
    margin: 0;
    left: 0;
    float: right;

    @media ${breakpoints.tablet} {
        display: ${(props) => (props.showMenu ? 'block' : 'none')};
        width: 100%;
        height: calc(100vh - 80px);
        position: absolute;
        top: 80px;
        padding: 0;
        background: ${color.white};
    }
`;

const MenuOpener = styled.button`
    display: none;
    height: 0;

    @media ${breakpoints.tablet} {
        line-height: 54px;
        width: 44px;
        height: 55px;
        display: block;
        position: relative;
        float: right;
        font-size: 40px;
        color: #fff;
        cursor: pointer;
        background: none;
        outline: none;
        border: none;
    }
`;

const ListElement = styled.li`
    display: inline-block;
    // margin: 0 30px 0 0;
    height: 80px;
    line-height: 80px;
    text-align: center;

    &:last-child {
        margin-right: 0;
    }

    @media ${breakpoints.tablet} {
        width: 100%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        height: auto;
        border-top: 1px solid ${color.lightGrey};

        &:last-child {
            margin-right: 0;
            border-bottom: 1px solid ${color.lightGrey};
        }
    }
`;

const Link = styled.a`
    color: #fff;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 100;
    padding: 0 10px 10px 10px;
    cursor: pointer;

    &:hover {
        color: #eee;
    }

    &.active {
        border-bottom: 1px solid #fff;
    }

    @media ${breakpoints.tablet} {
        display: block;
        position: relative;
        width: 100%;
        padding: 20px;
        line-height: 40px;
        background: ${color.white};
        text-align: center;
        color: #333;

        &.active {
            border-bottom: none;
            padding-bottom: 20px;
        }
    }
`;

const LocaleSelect = styled.select`
    background: none;
    border: 1px solid #fff;
    color: #fff;
    height: 38px;
    padding-left: 15px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    padding-right: 10px;

    option {
        color: #333;
    }

    @media ${breakpoints.tablet} {
        color: #333;
    }
`;

export default connect(null, mapDispatchToProps)(Navigation);
