/* import React, { FunctionComponent } from 'react';

const Gallery: FunctionComponent = () => {
    return (
        <>
            <h1>Gal</h1>
            <p>Photos</p>
        </>
    );
};

export default Gallery;
 */

import React, { FunctionComponent, RefObject } from 'react';
import styled from 'styled-components/macro';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

// import { breakpoints } from 'src/config';
import img from './../assets/Images';

const defaultImages = [
    {
        original: img.img1,
        thumbnail: img.img1,
    },
    {
        original: img.img2,
        thumbnail: img.img2,
    },
    {
        original: img.img3,
        thumbnail: img.img3,
    },
    {
        original: img.img4,
        thumbnail: img.img4,
    },
    {
        original: img.img5,
        thumbnail: img.img5,
    },
    {
        original: img.img6,
        thumbnail: img.img6,
    },
    {
        original: img.img7,
        thumbnail: img.img7,
    },
    {
        original: img.img7a,
        thumbnail: img.img7a,
    },
    {
        original: img.img8,
        thumbnail: img.img8,
    },
    {
        original: img.img9,
        thumbnail: img.img9,
    },
    {
        original: img.img9a,
        thumbnail: img.img9a,
    },
    {
        original: img.img9b,
        thumbnail: img.img9b,
    },
    {
        original: img.img10,
        thumbnail: img.img10,
    },
    {
        original: img.img11,
        thumbnail: img.img11,
    },
    {
        original: img.img12,
        thumbnail: img.img12,
    },
    {
        original: img.img13,
        thumbnail: img.img13,
    },
    {
        original: img.img14,
        thumbnail: img.img14,
    },
    {
        original: img.img15,
        thumbnail: img.img15,
    },
    {
        original: img.img16,
        thumbnail: img.img16,
    },
    {
        original: img.img17,
        thumbnail: img.img17,
    },
    {
        original: img.img18,
        thumbnail: img.img18,
    },
    {
        original: img.img19,
        thumbnail: img.img19,
    },
    {
        original: img.img19a,
        thumbnail: img.img19a,
    },
    {
        original: img.img20,
        thumbnail: img.img20,
    },
    {
        original: img.img21,
        thumbnail: img.img21,
    },
];

type Props = {
    reference: RefObject<any>;
};

const Gallery: FunctionComponent<Props> = ({ reference }) => {
    return (
        <Wrapper>
            <ToScroll ref={reference} />
            <GalleryWrapper>
                <ImageGallery
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={defaultImages}
                    lazyLoad
                    showIndex
                />
            </GalleryWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
`;

const GalleryWrapper = styled.div`
    padding: 10px;
    max-width: 1024px;
    margin: auto;
    min-height: auto;
    overflow: hidden;

    .image-gallery {
        margin: auto;
    }

    .image-gallery-slide img {
        max-width: 1024px;
    }
    .image-gallery-thumbnails-container {
        max-width: 1024px;
    }

    .image-gallery-image {
        max-height: 800px !important;
    }
`;

const ToScroll = styled.span`
    position: absolute;
    margin-top: -90px;
`;

export default Gallery;
